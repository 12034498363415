<template>
  <div>
    <condition :init-team-id="teamId" />
    <rate-condition :init-team-id="teamId" />
    <week-grade-rate />
    <div class="bottom">
      <img
        :src="require('@/assets/images/echarts/outDoor/bottom.png')"
        width="100%"
      />
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import condition from '@/views/echarts/outDoor/components/condition'
  import rateCondition from '@/views/echarts/outDoor/components/rateCondition'
  import weekGradeRate from '@/views/echarts/outDoor/components/weekGradeRate'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    components: { condition, rateCondition, weekGradeRate },
    data() {
      return {
        teamId: null,
      }
    },
    created() {
      const teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.teamId = teamInfo.Team_ID
      const userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'outDoorEcharts',
        '城管报表-店铺三包情况分析',
        'zzdcg.yy.gov.cn/index'
      )
    },
  }
</script>

<style scoped></style>
