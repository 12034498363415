<template>
  <div>
    <div class="select" @click="showPicker = true">
      {{ name }}
    </div>
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="中队"
        show-toolbar
        :columns="teamOptions"
        value-key="text"
        :colunms-field-names="{ text: 'label' }"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
  import { getTeam } from '@/api/base/base'

  export default {
    name: 'Team',
    props: {
      teamId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        value: null,
        name: '点击此处选择中队查看',
        showPicker: false,
        teamOptions: [],
      }
    },
    created() {
      this.getTeamOptions()
    },
    methods: {
      //选值触发，内含回调方法返回选项value值
      onConfirm(value) {
        this.name = value.text
        this.value = value.value
        this.$emit('teamChange', value.value)
        this.showPicker = false
      },

      //获取中队选项
      async getTeamOptions() {
        const { data } = await getTeam()
        this.teamOptions = data

        //有值则回绑
        if (this.teamId != null) this.bindOption()
      },

      //回绑方法
      bindOption() {
        this.teamOptions.forEach((option) => {
          if (option.value == this.teamId) {
            this.name = option.text
            this.value = { text: option.text, value: this.teamId }
          }
        })
      },

      //清空中队选择器
      reset() {
        this.name = ''
        this.value = {}
      },

      //刷新
      render(value) {
        this.teamOptions.forEach((option) => {
          if (option.value == value) {
            this.name = option.text
            this.value = { text: option.text, value: this.teamId }
          }
        })
      },
    },
  }
</script>

<style scoped>
  .select {
    background: #ddfde6;
    border: #d4d4d4 1px solid;
    width: 100%;
    height: 28px;
    text-align: center;
    margin: auto;
    font-size: 17px;
    border-radius: 10px;
  }
</style>
