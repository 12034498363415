<template>
  <div class="content">
    <ul class="menus">
      <li>当前店铺三包情况</li>
    </ul>
    <div class="nkuang" style="height:250px;">
      <team :team-id="teamId" @teamChange="teamChange" />
      <div id="conditionCharts" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  import team from '@/components/Echarts/Team'
  import { getConditionList } from '@/api/echarts/outDoor/outDoor'
  import { Toast } from 'vant'

  export default {
    name: 'Condition',
    components: { team },
    props: {
      initTeamId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        teamId: null,
        chart: [],
      }
    },
    created() {
      this.teamId = this.initTeamId
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })

        const { success, data, msg } = await getConditionList({
          TeamId: this.teamId,
        })
        if (success) {
          this.chart = data
          this.setEcharts()
        } else {
          Toast.fail(msg)
        }
        Toast.clear()
      },

      //中队选择器回调
      teamChange(value) {
        this.teamId = value
        this.fetchData()
      },

      //图表生成
      setEcharts() {
        let myChart = echarts.init(document.getElementById('conditionCharts'))
        let option
        option = {
          tooltip: {
            trigger: 'item',
          },
          legend: {
            top: '2%',
            left: 'center',
            right: '0%',
            bottom: '0%',
            //data: ['will'],
            //selected:{'will':true,'will1':false}
          },
          //控制上下左右
          grid: {
            top: '23%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true,
          },
          color: ['#00a490', '#fac858', '#ee6666'],
          series: [
            {
              name: '三包情况',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold',
                },
              },
              labelLine: {
                show: false,
              },
              label: {
                show: true,
                formatter: '{d}%',
                position: 'inside',
                color: 'white',
              },
              data: this.chart,
            },
          ],
        }
        if (option && typeof option === 'object') {
          myChart.setOption(option)
        }
      },
    },
  }
</script>

<style scoped>
  div,
  ul,
  li {
    margin: 0px;
    padding: 0px;
  }
  body {
    margin: 0px;
    padding: 0px;
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 11px 11px;
  }
  .content {
    width: 80%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .menus li:nth-last-child(1) {
    background: #07585d;
  }

  .nkuang {
    border: 1.2px #888888 dashed;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
  }
  .nkuang span {
    color: #f20000;
  }
  body {
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 12px 12px;
  }
  .content {
    width: 90%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c !important;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .nkuang {
    margin-top: 20px;
    border: 2px #313234 dashed;
    background: #fff;
  }
</style>
