import request from '@/util/request'

export function getConditionList(params) {
  return request({
    url: '/OutDoorEcharts.asmx/GetConditionList',
    method: 'GET',
    params,
  })
}

export function getRateCondition(params) {
  return request({
    url: '/OutDoorEcharts.asmx/GetRateCondition',
    method: 'GET',
    params,
  })
}

export function getWeekGradeRate(params) {
  return request({
    url: '/OutDoorEcharts.asmx/GetWeekGradeRate',
    method: 'GET',
    params,
  })
}
