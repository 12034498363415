<template>
  <div class="content">
    <ul class="menus">
      <li>{{ week }}评分覆盖率分析</li>
    </ul>
    <div class="nkuang" style="height:220px;">
      <div id="gradeRateCharts" style="height: 100%"></div>
    </div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'
  import { getWeekGradeRate } from '@/api/echarts/outDoor/outDoor'
  import { Toast } from 'vant'

  export default {
    name: 'Condition',
    data() {
      return {
        shopCount: [],
        gradeCount: [],
        gradeRate: [],
        week: '',
      }
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })

        const { success, data, msg } = await getWeekGradeRate({})
        if (success) {
          this.shopCount = data.ShopCount
          this.gradeCount = data.GradeCount
          this.gradeRate = data.GradeRate
          this.week = data.Week
          this.setEcharts()
        } else {
          Toast.fail(msg)
        }

        Toast.clear()
      },

      //图表生成
      setEcharts() {
        let myChart = echarts.init(document.getElementById('gradeRateCharts'))
        let option
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: { type: 'shadow' },
            formatter: function(params) {
              return '店铺上牌率<br />处理率：' + params[0].data + '%'
            },
          },
          legend: {},
          //控制上下左右
          grid: {
            top: '3%',
            left: '3%',
            right: '3%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'value',
          },
          yAxis: {
            type: 'category',
            data: ['阳明中队', '凤山中队', '梨洲中队', '兰江中队'],
            splitLine: {
              show: false, //去掉网格线
            },
          },
          series: [
            {
              barWidth: 24, //柱图宽度
              data: this.gradeRate,
              type: 'bar',
              itemStyle: {
                normal: {
                  color: function(params) {
                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                    let colorList = ['#00a490', '#fac858', '#ee6666', '#546fc6']
                    return colorList[params.dataIndex]
                  },
                },
              },
            },
          ],
        }

        if (option && typeof option === 'object') {
          myChart.setOption(option)
        }
      },
    },
  }
</script>

<style scoped>
  div,
  ul,
  li {
    margin: 0px;
    padding: 0px;
  }
  body {
    margin: 0px;
    padding: 0px;
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 11px 11px;
  }
  .content {
    width: 80%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .menus li:nth-last-child(1) {
    background: #07585d;
  }

  .nkuang {
    border: 1.2px #888888 dashed;
    border-radius: 15px;
    padding: 10px;
    font-size: 20px;
  }
  .nkuang span {
    color: #f20000;
  }
  body {
    height: inherit; /* 继承.box高度 */
    background: -webkit-linear-gradient(top, transparent 10px, #cbf2fe 11px),
      -webkit-linear-gradient(left, transparent 10px, #cbf2fe 11px);
    background-size: 12px 12px;
  }
  .content {
    width: 90%;
    margin: auto;
  }
  .menus {
  }
  .menus ul {
    margin: 0px;
    padding: 0px;
  }
  .menus li {
    background: #0a848c !important;
    list-style: none;
    margin-top: 20px;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 40px;
  }
  .nkuang {
    margin-top: 20px;
    border: 2px #313234 dashed;
    background: #fff;
  }
</style>
